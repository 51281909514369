<div class="credit-result">
  <!-- <div class="row">
    <div class="col-12">
      <div class="mat-h3">Decline Reasons / Approval Reasons</div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-lg-3 col-md-12">
      <div class="title">Reasons</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>Select Reasons</mat-label>
        <mat-select [formControl]="formControlReasonSelection">
          @for (reasonOption of reasonOptions; track reasonOption) {
            <mat-option  [value]="reasonOption">
              {{reasonOption.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-9 col-md-12">
      <mat-form-field>
        <mat-label>Reasons</mat-label>
        <textarea matInput rows="5" wrap="hard" maxlength="7000" [formControl]="formControlReason"></textarea>
      </mat-form-field>
    </div>
  </div>
  
  <mat-divider [inset]="true" class="mb-4"></mat-divider> -->

  <div class="row">
    <div class="col-12"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="space-between start"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start">
      <div class="mat-h3">Result</div>
      @if (!creditFlowResult.length && isAdminOrCreditUser) {
        <button mat-flat-button class="active w-lt-md-100" (click)="runCreditFlow()">Run Credit Flow</button>
      }
      @else if (isAdminOrCreditUser){
        <button mat-flat-button class="active w-lt-md-100" (click)="runCreditFlow()">Rerun Credit Flow</button>
      }
    </div>
  </div>
  @if (!creditFlowResult.length) {
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12">
        <message-box fxFlex="grow" type="warn">Credit flow has not started</message-box>
      </div>
    </div>
  }
  @if (creditFlowResult.length && !(creditFlowResult[creditFlowResult.length - 1].completeFlow)) {
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12">
        <message-box fxFlex="grow" type="info">Credit flow was not completed</message-box>
      </div>
    </div>
  }
  @if (creditFlowResult.length && creditFlowResult[creditFlowResult.length - 1].completeFlow) {
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12">
        <message-box fxFlex="grow" type="success">Credit flow has been completed</message-box>
      </div>
    </div>
  }
  <div class="row mb-2 result-item">
    <div class="col-lg-3 col-md-12">
      <div class="title">System Decision: </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center">
        <tag-box [state]="application.SystemCreditStatus ?? ''"></tag-box>
        <div>{{creditFlowResult.length ? ((creditFlowResult[creditFlowResult.length - 1].lastUpdatedTime) | date:'dd/MM/yyyy h:mm a') : ''}}</div>
      </div>
    </div>
  </div>
  @if (creditFlowResult.length && !(creditFlowResult[creditFlowResult.length - 1].completeFlow) && isAdminOrCreditUser) {
    <div class="row mb-2 result-item">
      <div class="col-lg-3 col-md-12">
        <div class="title">Resume Credit Flow</div>
      </div>
      <div class="col-lg-6 col-md-12">
        <span class="mdi mdi-refresh cursor-pointer" (click)="resumeCreditFlow()"></span>
      </div>
    </div>
  }
  <div class="row mb-2 result-item">
    <div class="col-lg-3 col-md-12">
      <div class="title">Credit Officer Decision: </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <tag-box [state]="application.CreditOfficerStatus ?? ''"></tag-box>
    </div>
  </div>
  <!-- <div class="row mb-2">
    <div class="col-lg-3 col-md-12">
      <div class="title">Queue: </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{getApplicationStage(application)}}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-3 col-md-12">
      <div class="title">Credit Product:</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{application.ApplicationType}}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-3 col-md-12">
      <div class="title">Finance Type:</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{getFinanceType(application)}}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-3 col-md-12">
      <div class="title">Requested Limit:</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{getLoanAmount(application) | looseCurrency}}</div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-3 col-md-12">
      <div class="title">Primary Applicant:</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{getCompanyName(application)}}</div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-lg-3 col-md-12">
      <div class="title">Principal Applicant:</div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div>{{application.Contacts?.GivenName}} {{application.Contacts?.SurName}}</div>
    </div>
  </div> -->

  @if (isAdminOrCreditUser){
    <mat-divider [inset]="true" class="mb-4"></mat-divider>
    <div class="row mb-2">
      <div class="col-12">
        <div class="mat-h3">Actions</div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12"
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="start start"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onCreditApprove()">Approve</button>
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="onDecline()">Decline</button>
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="moreInformation()">More Information</button>
        <button mat-flat-button color="primary" class="inactive w-lt-md-100 mb-2" (click)="requestForBankStatements()">Request Bank Statements</button>
      </div>
    </div>
  }

  <mat-divider [inset]="true" class="mb-4"></mat-divider>

  <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Alerts</div>
    </div>
  </div>

  <credit-result-alerts
    [creditFlowResult]="creditFlowResult"
    [application]="application"
  ></credit-result-alerts>
  
  <mat-divider [inset]="true" class="mb-4"></mat-divider>

  <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Services</div>
    </div>
  </div>

  <credit-result-web-services
    [data]="webServices"
    [application]="application"
    [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
    [apiUrl]="apiUrl"
    (viewReport)="onViewReport($event)"
  ></credit-result-web-services>

  <mat-divider [inset]="true" class="mb-4"></mat-divider>

  <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Duplicated Applications</div>
    </div>
  </div>

  <duplicated-applications
    [duplicatedApplications]="duplicatedApplications"
    (clickApplicationEvent)="onClick($event)"
  ></duplicated-applications>

  <mat-divider [inset]="true" class="mb-4"></mat-divider>

  <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Other Information</div>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Credit Flow Details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <credit-flow-details
        [creditFlowResult]="creditFlowResult"
      ></credit-flow-details>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Audit Log
        </mat-panel-title>
      </mat-expansion-panel-header>
      <credit-audit-log
        [application]="application"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
      ></credit-audit-log>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Credit Flow Details</div>
    </div>
  </div>

  <credit-flow-details
    [creditFlowResult]="creditFlowResult"
  ></credit-flow-details>

  <mat-divider [inset]="true" class="mb-4"></mat-divider>

  <div class="row mb-2">
    <div class="col-12">
      <div class="mat-h3">Audit Log</div>
    </div>
  </div>

  <credit-audit-log
    [application]="application"
    [getCreditAuditLogFn]="getCreditAuditLogFn"
  ></credit-audit-log> -->
</div>
