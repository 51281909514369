<!--error-notification *ngIf="showError" [errorTitle]="errorTitle" [errorMessage]="errorMessage" (onRetry)="retry()"></error-notification-->
@if (!((store.value$ | async)?.error)) {
  <div class="company-page container-fluid">
    <button class="arrow-left mb-2" (click)="onClickBack()">
      <span class="mdi mdi-arrow-left"></span>
    </button>
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <mat-form-field>
              <mat-label>Salesforce ID</mat-label>
              <input matInput type="text" [readonly]="true" [value]="originatorBusiness?.SalesforceId">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <originator-type [disableControl]="true" [formControl]="formControlType"></originator-type>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <originator-relationship  [formControl]="formControlRelationship"></originator-relationship>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <mat-form-field>
              <mat-label>Entity name</mat-label>
              <input matInput type="text" [formControl]="formControlEntityName">
              @for (errorKey of errorKeys(formControlEntityName); track errorKey) {
                <mat-error>
                  @if (formControlEntityName.touched && formControlEntityName.hasError(errorKey)) {
                    {{errorMessages(formControlEntityName, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <customer-entity-type  [formControl]="formControlEntityType"></customer-entity-type>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <abn [formControl]="formControlAbn">
            </abn>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <acn [formControl]="formControlAcn">
            </acn>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-8">
            <aggregator-list [getFn]="aggregatorGetFn"  [formControl]="formControlAggregator">
            </aggregator-list>
          </div>
        </div>
        -->
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <mat-form-field>
              <mat-label>Website</mat-label>
              <input matInput type="text" [formControl]="formControlWebsite">
              @for (errorKey of errorKeys(formControlWebsite); track errorKey) {
                <mat-error>
                  @if (formControlWebsite.touched && formControlWebsite.hasError(errorKey)) {
                    {{errorMessages(formControlWebsite, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <mobile [formControl]="formControlTelephoneNumber" [onlyMobile]="false"
            title="Phone number"></mobile>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <rate-card-list [getFn]="ratecardGetFn"  [formControl]="formControlRatecard">
            </rate-card-list>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <mat-form-field>
              <mat-label>Percentage Paid</mat-label>
              <input matInput type="text" [formControl]="formControlPercentagePaid">
              <span class="mdi mdi-percent" matSuffix></span>
              @for (errorKey of errorKeys(formControlPercentagePaid); track errorKey) {
                <mat-error>
                  @if (formControlPercentagePaid.touched && formControlPercentagePaid.hasError(errorKey)) {
                    {{errorMessages(formControlPercentagePaid, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12"
            fxLayoutGap="10px"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <button mat-flat-button color="primary" class="float-right w-lt-md-100"
            [disabled]="formGroup.invalid || (loader.update.inProgress$ | async) || (loader.loading.inProgress$ | async)"
            (click)="update($event)">
              {{(loader.loading.inProgress$ | async) ? 'Loading ...' : (loader.update.inProgress$ | async) ? "Saving..." : "Save Changes"}}
            </button>
            <button mat-stroked-button color="primary" class="float-left w-lt-md-100" (click)="onClickBack()">Cancel</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}
