import {Component, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  ApiResponse,
  Application,
  applicationStageAllowSettleLoan,
  AssetSettlementGetApplicationAssetFn,
  AzureStorageDocument,
  Base64File,
  ConfirmationDialogResult,
  constants,
  DeleteApprovalNoticeFileFn,
  Disbursement,
  DocumentNotificationDialogResult,
  DocumentTag,
  DocumentWorklistTableData,
  GenerateApprovalNoticeFn,
  getAllDefaultTags,
  GetApplicationByIdFn,
  GetApplicationOwnerFn, getDeposit,
  GetDisbursementByApplicationIdFn,
  GetUserFn,
  GroupedDocument,
  groupStandardCondition,
  Metadata,
  PayloadApiResponse,
  PpsrAsset,
  SelectableTagsDialogResult,
  SendApprovalNoticeEmailFn,
  SettleLoanFn,
  TermRateForBusinessOverdraft,
  UpdateApplicationStageInSfFn,
  UploadAzureFile,
  UploadFilesMultiTagsDialogResult,
  User, 
  validateAssetDepositAgainstApplicationDeposit,
  validateAssetDeposits,
  validateDisbursementData,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DocumentReviewEmailBody,
  GetApplicationCreditOfficerFn,
} from '@portal-workspace/grow-shared-library';
import {isInternalUser} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource, SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {ApplicationDialogService} from '../application-dialog-component/application-dialog.service';
import {PortalHotToastService} from '../portal-hot-toast-component/hot-toast.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {getUser, openWindowAndDownloadWithFilename, setupUntilDestroy, applicationDefaultDocuments, LESS_THAN_CREDIT_APPROVED_STAGE, GREATER_THAN_CREDIT_APPROVED_STAGE,} from '@portal-workspace/grow-ui-library';
import {applicantIndividual, getApplicationCustomerName, } from '@portal-workspace/grow-shared-library';
import {switchMap, tap} from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { defaultDocuments, formGroupedDocumentData, unclassifiedDocuments } from '@portal-workspace/grow-shared-library';
import {loadingFor} from '@ngneat/loadoff';
import { ActivatedRoute } from '@angular/router';
import { DocumentDetailsSubTableComponent } from './document-details-sub-table.component';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component"
import { TagBoxComponent } from '../message-box/tag-box.component';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {MatTabsModule} from '@angular/material/tabs';
import { NgClass, AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {
 isExternalUser,
} from '@portal-workspace/grow-shared-library';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { PpsrService } from 'apps/portal2/src/app/service/ppsr.service';
import _ from 'lodash';
import { MatCardModule } from '@angular/material/card';

export class ApplicationDetailsDocumentDetailsComponentInternalDataSource extends DataSource<GroupedDocument> {

  subject: Subject<GroupedDocument[]> = new BehaviorSubject<GroupedDocument[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<GroupedDocument[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(docs: GroupedDocument[]) {
    this.subject.next(docs);
  }
}

export type ListApplicationDocumentFn = (applicationId: number) => Observable<AzureStorageDocument[]>;
export type ListAccreditationDocumentFn = (salesforceId: string) => Observable<AzureStorageDocument[]>;
export type UploadAccreditationDocumentFn = (salesforceId: string, newFiles: Base64File[], deletedFileNames: string[]) => Observable<ApiResponse>;
export type DownloadApplicationDocumentUrlFn = (path: string, fileName: string, applicationId: number) => Observable<Blob>;
export type DownloadAllApplicationDocumentUrlFn = (applicationId: number, customerName: string, userId: number) => Observable<Blob>;
export type ApproveApplicationDocumentFn = (path: string, fileName: string, stateName: string, userId: number | undefined) => Observable<ApiResponse>;
export type DeclineApplicationDocumentFn = (path: string, fileName: string, stateName: string, userId: number | undefined) => Observable<ApiResponse>;
export type UndoApplicationDocumentFn = (path: string, fileName: string, stateName: string, userId: number | undefined) => Observable<ApiResponse>;
export type DeleteApplicationDocumentFn = (path: string, fileName: string, stateName: string) => Observable<ApiResponse>;
export type UpdateApplicationDocumentTagsFn = (path: string, fileName: string, tags: string[]) => Observable<ApiResponse>;
export type UpdateApplicationRequiredDocumentsFn = (applicationId: number, data: DocumentTag[]) => Observable<Application>;
export type DownloadAccreditationDocumentUrlFn = (path: string, fileName: string) => Observable<Blob>;
export type DownloadAllAccreditationDocumentUrlFn = (salesforceId: string, customerName: string, userId: number) => Observable<Blob>;
export type SendApplicationDocumentNotificationFn = (
  documents: GroupedDocument[],
  additionalInfo: string,
  brokerAppId: string,
  applicationId: number,
  brokerName: string,
  emailList: string[],
  borrowerName: string,
  userId: number,
) => Observable<ApiResponse>;
export type CreateDocumentWorklistFn = (worklist: DocumentWorklistTableData) => Observable<PayloadApiResponse<boolean>>
export type CompleteDocumentWorklistFn = (applicationId: number) => Observable<ApiResponse>;

export interface DocumentDetailsComponentEvent {
  files: File[];
  removefileNames: string[];
  isNonStandardConditionDocument?: boolean;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'document-details',
    templateUrl: './document-details.component.html',
    styleUrls: ['./document-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', display: 'block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [FlexModule, MatTabsModule,MatCardModule, NgTemplateOutlet,MatButtonModule, MatTooltipModule, MatFormFieldModule, MatMenuModule, MatDividerModule, CustomContentLoaderComponent, NgClass, ExtendedModule, MatTableModule, TagBoxComponent, DocumentDetailsSubTableComponent, AsyncPipe, MatCheckboxModule]
})
export class DocumentDetailsComponent implements OnInit, OnChanges {
  subscriptions: Subscription[] = [];
  loader = loadingFor('documentDetails');

  dataSource = new ApplicationDetailsDocumentDetailsComponentInternalDataSource();
  dataSourceNonStandardConditions = new ApplicationDetailsDocumentDetailsComponentInternalDataSource();
  dataSourceStandardConditions = new ApplicationDetailsDocumentDetailsComponentInternalDataSource();
  salesforceId: string = '';
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;

  docs: GroupedDocument[] = [];
  standardDocs: GroupedDocument[] = [];
  nonStandardConditionDocs: GroupedDocument[] = [];
  standardConditionDocs: GroupedDocument[] = [];
  documentDetails = [];
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  getApplicationCustomerName = getApplicationCustomerName;
  applicationDefaultDocuments = applicationDefaultDocuments;
  groupStandardCondition = groupStandardCondition;
  isExternalUser = isExternalUser;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  getApplicationCreditOfficerFn!: GetApplicationCreditOfficerFn
  
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) application!: Application;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input({required: true}) sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: false}) businessLoanData!: TermRateForBusinessOverdraft;
  @Input({required: false}) isAssetEmpty!: boolean;
  @Input({required: false}) notAllAssetAccepted!: boolean;
  @Input({required: false}) totalNotesCount!: number;
  @Input({required: false}) missingBankDetails!: null | 'customer' | 'broker';
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
   
  @Output() scrollEvent = new EventEmitter<void>();
  @Output() saveNote = new EventEmitter();

  
  disableAddAsset: boolean = false
  isAssetApplication!: boolean;
  isBusinessApplication!: boolean;
  isCorporateLoanApplication!: boolean;
  assetStatus:PpsrAsset[]=[];
  columnsToDisplay: string[] = ['icon', 'groupName', 'status', 'documents', 'checkBox', 'action'];
  expandedElement!: GroupedDocument | null | undefined;
  expandedElementNonStandardCondition!: GroupedDocument | null | undefined;
  expandedElementStandardCondition!: GroupedDocument | null | undefined;
  dragElement!: GroupedDocument | null | undefined;
  defaultDocuments = defaultDocuments // comes from this.application;
  isInternalUser = isInternalUser;
  Math = Math;
  user: User | null = null;

  loggedInUser: User | null = getUser();
   showCheckboxColumn: boolean = false;
  globalSelected = false; // select all checkboxes
  selection = new SelectionModel<any>(true, []);
   selectedGroupName = new SelectionModel<GroupedDocument>(true, []);

  @Output() events = new EventEmitter<DocumentDetailsComponentEvent>();

  constructor(private dialogService: ApplicationDialogService,
    private route: ActivatedRoute,
    private toastService: PortalHotToastService,
    private applicationService: ApplicationService,
    private ppsrService: PpsrService,) { }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser();
    this.application = (this.route.snapshot.data as any).application
    this.getApplicationCreditOfficerFn = this.applicationService.getApplicationCreditOfficerFn
    this.reload();
  }
  
  getApplicationAsset() {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((res: PpsrAsset[]) => {
        const stageName = this.application.AppInfoStageName
        if (res.length) {
          if(stageName == 'Closed Won' || stageName == 'Settlement') {
            this.disableAddAsset = false
          }
          this.assetStatus = res.filter(r => r.status === 'accepted');
          if (res.length === this.assetStatus.length) {
            this.notAllAssetAccepted = false
          }
          else {
            this.notAllAssetAccepted = true
          }
        } else {
          this.disableAddAsset = false;
        }
      })
    )
  }

  private displayRequiredDocumentsOnly() {
    this.docs = this.docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }

  private filterInternalDocuments() {
    if (!isInternalUser(this.user)) {
      this.docs = this.docs.filter(group => group.value !== constants.documentTypes.internalDocuments.value);
    }
  }

  get applicationDocumentTypes() {
    const tags = this.application?.DocumentTypes?.length ? this.application?.DocumentTypes.map(t => {
      return {
        ...t,
        value: t.value.toLowerCase()
      }
    }) : [];
    const documentTypes: DocumentTag[] = [];
    for (const tag of tags) {
      if (!documentTypes.map(t => t.value).includes(tag.value)) {
        documentTypes.push(tag)
      }
    }
    return documentTypes;
  }

  reload() {
    switch(this.application?.ApplicationType) {
      case 'AssetFinance':
      case 'Consumer': {
        this.isAssetApplication = true;
        this.isBusinessApplication = false;
        this.isCorporateLoanApplication = false;
        break;
      }
      case 'BusinessLoans':
      case 'BusinessOverdraft': {
        this.isAssetApplication = false;
        this.isBusinessApplication = true;
        this.isCorporateLoanApplication = false;
        break;
      }
      case 'CorporateLoans': {
        this.isAssetApplication = false;
        this.isBusinessApplication = false;
        this.isCorporateLoanApplication = true;
        break;
      }
    }

    this.docs.length = 0;
    this.salesforceId = this.application.AppInfo.SalesforceId ?? '';
    const applicant = this.application.Individuals.find(i => i.Role === "Applicant");
    const primaryEntity = this.application.CommercialEntities.find(e => e.Type === "Primary");

    this.subscriptions.push(
      this.listApplicationDocumentFn(this.application.ApplicationId).pipe(
        this.loader.documentDetails.track(),
        tap((r: AzureStorageDocument[]) => {
          if (r) {
            console.log('r====', r);
            this.docs = this.formGroupedDocumentData(r,
              this.applicationDocumentTypes?.length ? this.applicationDocumentTypes
                : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
              this.application.ApplicationType === "Consumer" ? applicant?.GivenName + " " + applicant?.SurName : (primaryEntity?.LegalName ?? ""),
            );

            this.docs = this.groupStandardCondition(this.docs, this.application);
            this.displayRequiredDocumentsOnly();
            this.filterInternalDocuments();

            this.standardDocs = this.docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
            this.nonStandardConditionDocs = this.docs.filter(d => d?.nonStandardCondition);
            this.standardConditionDocs = this.docs.filter(d => d?.standardCondition);
            this.dataSource.update(this.standardDocs);
            this.dataSourceNonStandardConditions.update(this.nonStandardConditionDocs);
            this.dataSourceStandardConditions.update(this.standardConditionDocs);
            console.log('all docs============', this.docs);
            console.log('major table========', this.standardDocs);
            console.log('standard conditions===========', this.standardConditionDocs);
            console.log('non-standard conditions============',this. nonStandardConditionDocs);
            if (this.expandedElement) {
              this.expandedElement = this.standardDocs.find(obj => obj.value === (this.expandedElement as GroupedDocument).value);
            }
            if (this.expandedElementNonStandardCondition) {
              this.expandedElementNonStandardCondition = this.nonStandardConditionDocs.find(obj => obj.value === (this.expandedElementNonStandardCondition as GroupedDocument).value);
            }
            if (this.expandedElementStandardCondition) {
              this.expandedElementStandardCondition = this.standardConditionDocs.find(obj => obj.value === (this.expandedElementStandardCondition as GroupedDocument).value);
            }
          }
        })
      ).subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes as any).application) {
      this.reload();
      this.getApplicationAsset()
      
    }
    
  }

  disableSettleLoan($event: boolean){
    this.disableAddAsset = $event
    this.notAllAssetAccepted = $event
  }

  disableOnPPSRStatus($event: boolean){
    this.notAllAssetAccepted = $event
  }
  get metadata(): Metadata {
    if (this.application.ApplicationType === 'Consumer') {
      const applicant = this.application?.Individuals.find(i => i.Role === 'Applicant');
      return {
        consumername: applicant?.GivenName + ' ' + applicant?.SurName,
        dob: applicant?.DoB as string
      }
    } else {
      const entity = this.application?.CommercialEntities?.find(e => e.Type === 'Primary');
      if (entity) {
        return {
          legalname: entity?.LegalName,
          abn: entity?.ABN
        }
      }
    }

    return {};
  }


  onCopyToClipboardClicked($event: Event) {
    this.toastService.quickInfoToast(`SalesforceId copied to clipboard`);
  }

  onSelectionChanged($event: MatCheckboxChange, element: GroupedDocument) {
    this.globalSelected = false;
    if($event.checked) {
      this.selectedGroupName.select(element)
    } else {
      this.selectedGroupName.deselect(element)
    }
  }

  onSubmitConditionForReview(){
    this.showCheckboxColumn= true
  }

  scrollToNotesPage():void{
    this.scrollEvent.emit();
  }

  onSubmitDocuments(){
    let docs: GroupedDocument[]  = this.groupStandardCondition(this.docs, this.application);
    docs = docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })

    let standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
    let nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition && d?.nonStandardCondition && d.nonStandardConditionStatus !== 'Accepted');
    let standardConditionDocs = docs.filter(d => d?.standardCondition);
    let allGroups = [...standardDocs,...nonStandardConditionDocs,...standardConditionDocs].filter(item => item.standardConditionStatus !== 'Accepted' );
    
      this.subscriptions.push(
        this.dialogService.openDocumentReviewDialog ({
          title: 'Review Documents/Conditions',
          groupNames:allGroups,
          application: this.application,
          isShowRequestReviewSection:true
        }).afterClosed().pipe(
          tap(async (r) => {
            if(r && r.valid == true){ 
            this.selectedGroupName.clear()
            let groupNameArray:string[] = []
            let pendingTagArray:string[] = []
            let inProgressTagArray:string[] = []
            let submitterEmailList = r.submitterEmailList
            let documentNotes = r.documentNotes

            r.groupNames.forEach(element => {
              if(element.status === "Pending"){
                pendingTagArray.push(element.groupName)
              }else if(element.status === "In Progress"){
                inProgressTagArray.push(element.groupName)
              }
              groupNameArray.push(element.groupName)
              this.selectedGroupName.select(element)
            });

            if (isExternalUser(this.loggedInUser) && this.application.AppInfo.StageName && this.application.AppInfoSalesforceID) {
              const stageName = this.application.AppInfo.StageName.toLocaleLowerCase();
              
              const applicationOwnerApiCall = this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
              .pipe(this.toastService.spinnerObservable());
          
              const applicationCreditOfficerApiCall = this.getApplicationCreditOfficerFn(this.application.AppInfoSalesforceID)
              .pipe(this.toastService.spinnerObservable());

              if (LESS_THAN_CREDIT_APPROVED_STAGE.includes(stageName)) {
                combineLatest([applicationOwnerApiCall, applicationCreditOfficerApiCall]).subscribe(
                  ([applicationOwner, applicationCreditOfficer]) => {
                    // Process the ApplicationOwner email
                    if (applicationOwner && applicationOwner.Email) {
                      submitterEmailList.push(applicationOwner.Email);
                    } else {
                      submitterEmailList.push("sales@dynamoney.com");
                    }
            
                    // Process the ApplicationCreditOfficer email
                    if (applicationCreditOfficer && applicationCreditOfficer.CreditOfficerEmail) {
                      submitterEmailList.push(applicationCreditOfficer.CreditOfficerEmail);
                    } else {
                      submitterEmailList.push("credit@dynamoney.com");
                    }


                    this.sendDocumentReviewEmail(r.isSubmitterSelect,submitterEmailList,documentNotes,pendingTagArray,inProgressTagArray)
                  }
                );

              }

              if (GREATER_THAN_CREDIT_APPROVED_STAGE.includes(stageName)) {
                applicationOwnerApiCall.subscribe((applicationOwner) => {
                  if (applicationOwner && applicationOwner.Email) {
                    submitterEmailList.push(applicationOwner.Email);
                  } else {
                    submitterEmailList.push("sales@dynamoney.com");
                  }
            
                  // Push contract email
                  submitterEmailList.push("contract@dynamoney.com");
                });

                this.sendDocumentReviewEmail(r.isSubmitterSelect,submitterEmailList,documentNotes,pendingTagArray,inProgressTagArray)
              }
            } else {
              if(submitterEmailList.length > 0){
                this.sendDocumentReviewEmail(r.isSubmitterSelect,submitterEmailList,documentNotes,pendingTagArray,inProgressTagArray)
                }
            }
            
            const notificationSelectedMessage:string[] = [];
            
            if(r.isSubmitterSelect){
              notificationSelectedMessage.push("Submitter")
            }
            if(r.isSalesSelect){
              notificationSelectedMessage.push("Sales")
            }

            if(r.isCreditSelect){
              notificationSelectedMessage.push("Credit")
            }
            if(r.isSettlementSelect){
              notificationSelectedMessage.push("Settlements")
            }


            let notificationSubmitter = ""
            let documentConditionDetail = "";

            if(notificationSelectedMessage.length){
              
              notificationSubmitter = "Notification: "+ notificationSelectedMessage.join(", ")
              
              if(r.otherEmailList.length > 0){
                notificationSubmitter = notificationSubmitter +", "+ r.otherEmailList.join(", ")
              }
            }

            if(groupNameArray.length){
              documentConditionDetail = "Documents/Conditions Review: "+ groupNameArray.join(", ")
            }
            this.onSubmitForReview(r.isAssignWorklist,r.submitterEmailList.length);

            if (!_.isEmpty(documentNotes) || !_.isEmpty(notificationSubmitter) ||  !_.isEmpty(documentConditionDetail)){
              const applicationId = this.route.snapshot.paramMap.get('applicationId') || ''
              const data = {
                UserId: this.loggedInUser?.UserId!,
                ApplicationId: parseInt(applicationId),
                Note: documentNotes ?? '',
                visibleToInternalStaff  : r.isInternalUser ?? false,
                notesFromDscrCalculator: true,
                NotificationSubmitter:notificationSubmitter,
                DocumentConditionDetail:documentConditionDetail,
              }
              this.subscriptions.push(this.ppsrService.createNoteFn(data).pipe(
                tap(r => {
                  this.reloadNotes();
                  this.reload();
                })
              ).subscribe())
            }
              
            }
          })
        ).subscribe()
      )
  }

  reloadNotes(){
    this.saveNote.emit(true);
  }
  
 sendDocumentReviewEmail(isSubmitterSelect:boolean,submitterEmailList:string[],documentNotes:string,pendingTagArray:string[],inProgressTagArray:string[]) {
  let allEmailList = submitterEmailList
  const documentReviewEmailBody: DocumentReviewEmailBody = {
    applicationId: this.application.ApplicationId,
    brokerAppId: this.application.BrokerAppId,
    emailList: allEmailList,
    applicationType:this.application.ApplicationType,
    isSubmitterSelect: isSubmitterSelect,
    documentNotes: documentNotes,
    isFromDocumentReview: true,
    pendingTag:pendingTagArray,
    inProgressTag:inProgressTagArray,
    applicationName: this.application.entityName,
    submitterUserId: this.application.UserId,
  }
   
  this.subscriptions.push(this.applicationService.sendDocumentReviewEmailFn(documentReviewEmailBody).pipe().subscribe())

 }
  oldonSubmitDocuments(){ 
    const selectedGroupNames = this.selectedGroupName.selected
    .filter(item => item.status === 'In Progress');
    if(selectedGroupNames.length){
      this.subscriptions.push(
        this.dialogService.openDocumentReviewDialog ({
         title: 'Review Documents/Conditions',
         groupNames:selectedGroupNames,
         application: this.application,
         isShowRequestReviewSection:true
       }).afterClosed().pipe(
         tap(async (r) => {
           if(r && r.valid == true){
              this.selectedGroupName.clear()
              r.groupNames.forEach(element => {
                this.selectedGroupName.select(element)
              });

             //this.onSubmitForReview();
             //this.subscriptions.push(this.applicationService.sendDocumentReviewEmailFn({notes:r.documentNotes}).pipe().subscribe())
           }
         })
       ).subscribe((res)=>{
        if(res){
          this.selectedGroupName.clear()
        }
       })
     )
    }else {

    }
  }
  // checkboxClear(){
  //   for (const user of this.selectedGroupName.selected) {
  //     this.selectedGroupName.deselect(user)
  //   }
  // }

  onAssetSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.dialogService.openSettleLoanDialog(true)
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendAssetAndDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      this.dialogService.openSettleLoanDialog(false).afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            // check documents first
            this.subscriptions.push(
              combineLatest([
                this.listApplicationDocumentFn(this.application.ApplicationId),
                this.getApplicationByIdFn(this.application.ApplicationId)
              ]).pipe(
                  this.toastService.spinnerObservable()
                )
                .subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
                if (r) {
                  let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
                    app?.DocumentTypes?.length ? app?.DocumentTypes
                    : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
                    '',
                  );
                  docs = this.groupStandardCondition(docs, this.application);
                  docs = docs.filter(group => {
                    if (
                      group.value === constants.documentTypes.internalDocuments.value ||
                      group.required || group.docs?.length
                    ) {
                      return true;
                    }
                    return false;
                  })

                  const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
                  const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
                  const standardConditionDocs = docs.filter(d => d?.standardCondition);
                  const pendingGroups = [
                    ...standardDocs.filter(group => group.status === 'Pending'),
                    ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
                    ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
                  ];
                   
                  if (pendingGroups.length) {
                    // do not allow settle loan
                    this.dialogService.openAlertDialog({
                      message: 'Error',
                      subMessage: 'You cannot request loan settlement when you have pending items on the documents tab.'
                    }).afterClosed().subscribe();
                  } else {
                    this.doUpdateApplicationStage('QA');
                  }
                }
              })
            )
          }
        })
      ).subscribe()

    }
  }

  doSendAssetAndDisbursementToSf() {
    this.subscriptions.push(
      combineLatest([
        this.getApplicationAssetFn(this.application.ApplicationId),
        this.getDisbursementByApplicationIdFn(this.application.ApplicationId)
      ]).pipe(
        switchMap(([assets, disbursements]: [PpsrAsset[], Disbursement[]]) => {
          // WEB-3702
          // validate asset(s) individual deposit
          const {valid: assetDepositValid, messages} = validateAssetDeposits(assets);
          if (!assetDepositValid) {
            return of ({
              status: false,
              message: messages.join('<br/>')
            })
          }

          // WEB-3702
          // validate asset(s) deposit against application's deposit
          const appDeposit = getDeposit(this.application);
          const {totalDeposit, applicationDeposit, valid: applicationDepositValid} = validateAssetDepositAgainstApplicationDeposit(Number(appDeposit), assets);
          if (!applicationDepositValid) {
            return of ({
              status: false,
              message: `Application deposit $${applicationDeposit} doesn't match with total asset deposit $${totalDeposit}`,
            });
          }

          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
           
          return this.settleLoanFn({
            assets: assets,
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }

      })
    )
  }

  doUpdateApplicationStage(stageName: string, notification = true) {
    if (stageName) {
      this.subscriptions.push(
        this.updateApplicationStageFn({
          salesforceId: this.application.AppInfoSalesforceID ?? "",
          stageName: stageName
        }).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((response: ApiResponse) => {
          if (response.status) {
            if (notification) {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Application sent to ${stageName} queue`,
              }).afterClosed().subscribe();
            }

            // Application stage needs to refresh on the page
            // This is the best I can do - as updateApplicationStageFn changes the stage in SF, and it takes some time
            // to sync the SF changes to the database. That's why I need to put the setTimeout here
            setTimeout(() => {
              this.getApplicationByIdFn(this.application.ApplicationId).subscribe((application: Application) => {
                this.application = application as any;
              })
            }, 3000);
          } else {
            this.dialogService.openAlertDialog({
              message: 'Error',
              subMessage: response.message,
            }).afterClosed().subscribe();
          }
        })
      )
    }
  }

  onCorporateLoanSettleLoan() {
    // similar to Business Term Loan
    this.onBusinessSettleLoan();
  }

  onBusinessSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.dialogService.openSettleLoanDialog()
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      // check documents first
      this.subscriptions.push(
        combineLatest([
          this.listApplicationDocumentFn(this.application.ApplicationId),
          this.getApplicationByIdFn(this.application.ApplicationId)
        ]).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
          if (r) {
            let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
              app?.DocumentTypes?.length ? app?.DocumentTypes
              : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
              '',
            );
            docs = this.groupStandardCondition(docs, this.application);
            docs = docs.filter(group => {
              if (
                group.value === constants.documentTypes.internalDocuments.value ||
                group.required || group.docs?.length
              ) {
                return true;
              }
              return false;
            })
            const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
            const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
            const standardConditionDocs = docs.filter(d => d?.standardCondition);
            const pendingGroups = [
              ...standardDocs.filter(group => group.status === 'Pending'),
              ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
              ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
            ];
             
            if (pendingGroups.length) {
              // do not allow settle loan
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: 'You cannot settle loan as you still have pending documents',
              }).afterClosed().subscribe();
            } else {
              this.doUpdateApplicationStage('QA');
            }
          }
        })
      )
    }
  }

  doSendDisbursementToSf() {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn(this.application.ApplicationId).pipe(
        switchMap((disbursements: Disbursement[]) => {
          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
          return this.settleLoanFn({
            assets: [],
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
      })
    )
  }


  onDownloadAll() {
    // const a = document.createElement('a') as any;
    // a.href = this.downloadAllApplicationDocumentUrlFn(this.application.ApplicationId);
    // document.body.appendChild(a);
    // a.click();
    const customerName = this.getApplicationCustomerName(this.application)
    const applicationId = this.application.ApplicationId
    const appId = 'G' + '0'.repeat((7 - String(applicationId).length)) + String(applicationId);
    this.downloadAllApplicationDocumentUrlFn(
      applicationId,
      customerName,
      (this.user)?.UserId ?? 0
    ).pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename(customerName + '-' + appId + '.zip', blob)
      })
    ).subscribe()
    // window.open(url);
  }
  
  onUploadDocument(fileType?: string) {
    const allTags: DocumentTag[] = this.applicationDocumentTypes?.length
      ? [...this.applicationDocumentTypes]
      : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];
    for (const tag of applicationDefaultDocuments(this.application, true)) {
      if (!allTags.find(t => t.value === tag.value)) {
        allTags.push(tag);
      }
    }

    this.subscriptions.push(this.dialogService.openUploadFileMultiTagsDialog({
      title: 'Upload document',
      tags: fileType ? [fileType] : [],
      allTags: allTags
    }).afterClosed().pipe(
      tap((r: UploadFilesMultiTagsDialogResult | undefined) => {
        if (r) {
          console.log('================on upload document: ', r);
          const files = r.files;
          if (files && files.length && r.valid) {
            const isNonStandardConditionDocument = r?.selectedTags.find((item)=>item.nonStandardCondition)?.nonStandardCondition;
            this.events.emit({
              files, removefileNames: [], isNonStandardConditionDocument
            });
          }

          // need to double-check the tags
          const selectedTagNames = r.files.map(file => file.tags).reduce((a, b) => [...(a ?? []), ...(b ?? [])], []) ?? [];
          const selectedTags = selectedTagNames.map(name => 
            (Object.values(constants.documentTypes) as DocumentTag[]).find(tag => tag.value === name)
          ).filter(tag => !!tag) as DocumentTag[];
          const existingTags = this.applicationDocumentTypes?.length ?
            this.applicationDocumentTypes : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];

          let tagChanges = false;
          for (const tag of selectedTags) {
            if (!existingTags.map(t => t.type).includes(tag.type)) {
              existingTags.push(tag);
              tagChanges = true;
            }
          }


          if (tagChanges) {
            this.updateApplicationRequiredDocumentsFn(
              this.application.ApplicationId,
              existingTags
            ).subscribe();
          }
          // if(r.valid){
          //   const nonStandardConditionDocument = selectedTags.find((item)=>item.nonStandardCondition)
          //   if(nonStandardConditionDocument){
          //     this.onNonStandardConditionDocUpload()
          //   }
          // }
        }
      })
    ).subscribe());
  }

  onApproveDocument(docs: AzureStorageDocument[], statusName: string, type: 'standardCondition' | 'nonStandardCondition' | null = null) {
    const tags: DocumentTag[] = this.application.DocumentTypes ?? [];
    const index = tags.findIndex(t => t.value === statusName);
    const tagObj = tags.find(t => t.value === statusName);
    if (type && !docs?.length) {
      if (tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, nonStandardCondition: true, nonStandardConditionStatus: "Accepted"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "Accepted"}
        }
      } else {
        if (type === 'nonStandardCondition') {
          const group = this.nonStandardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              nonStandardCondition: true,
              nonStandardConditionStatus: "Accepted"
            })
          }
        } else {
          const group = this.standardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              standardCondition: true,
              standardConditionStatus: "Accepted"
            })
          }
        }
      }
      this.updateApplicationRequiredDocumentsFn(
        this.application.ApplicationId,
        tags
      ).subscribe((a: Application) => {
        this.application = a;
        this.reload();
      });
    } else {
      const subs: Observable<any>[] = docs.map(doc => this.approveApplicationDocumentFn(doc.name, "", statusName, this.user?.UserId));
      if (type && tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, nonStandardCondition: true, nonStandardConditionStatus: "Accepted"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "Accepted"}
        }
        subs.push(this.updateApplicationRequiredDocumentsFn(
          this.application.ApplicationId,
          tags
        ))
      }
      this.subscriptions.push(
        combineLatest(subs).pipe(
          this.toastService.retryableMessage({
            successMessage: 'Documents Approved',
            errorMessage: 'Failed to approve the documents',
            retryFn: ()=> {
              console.log('**** retry ', this);
              this.onApproveDocument(docs, statusName);
            }
          }),
          tap(r => {
            this.reload();
          })
        ).subscribe()
      );
    }
  }

  onDeclineDocument(docs: AzureStorageDocument[], statusName: string, type: 'standardCondition' | 'nonStandardCondition' | null = null) {
    const tags: DocumentTag[] = this.application.DocumentTypes ?? [];
    const index = tags.findIndex(t => t.value === statusName);
    const tagObj = tags.find(t => t.value === statusName);
    if (type && !docs?.length) {
      if (tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, standardCondition: true, nonStandardConditionStatus: "Rejected"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "Rejected"}
        }
      } else {
        if (type === 'nonStandardCondition') {
          const group = this.nonStandardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              nonStandardCondition: true,
              nonStandardConditionStatus: "Rejected"
            })
          }
        } else {
          const group = this.standardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              standardCondition: true,
              standardConditionStatus: "Rejected"
            })
          }
        }
      }
      this.updateApplicationRequiredDocumentsFn(
        this.application.ApplicationId,
        tags
      ).subscribe((a: Application) => {
        this.application = a;
        this.reload();
      });
    } else {
      const subs: Observable<any>[] = docs.map(doc => this.declineApplicationDocumentFn(doc.name, "", statusName, this.user?.UserId));
      if (type && tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, nonStandardCondition: true, nonStandardConditionStatus: "Rejected"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "Rejected"}
        }
        subs.push(this.updateApplicationRequiredDocumentsFn(
          this.application.ApplicationId,
          tags
        ))
      }
      this.subscriptions.push(
        combineLatest(subs).pipe(
          this.toastService.retryableMessage({
            successMessage: 'Documents Declined',
            errorMessage: 'Failed to decline the documents',
            retryFn: () => {
              console.log('**** retry ', this);
              this.onDeclineDocument(docs, statusName);
            }
          }),
          tap(r => {
            this.reload();
          })
        ).subscribe()
      );
    }
  }

  onUndoDocument(docs: AzureStorageDocument[], statusName: string, type: 'standardCondition' | 'nonStandardCondition' | null = null) {
    const tags: DocumentTag[] = this.application.DocumentTypes ?? [];
    const index = tags.findIndex(t => t.value === statusName);
    const tagObj = tags.find(t => t.value === statusName);
    if (type && !docs?.length) {
      if (tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, standardCondition: true, nonStandardConditionStatus: "Pending"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "Pending"}
        }
      } else {
        if (type === 'nonStandardCondition') {
          const group = this.nonStandardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              nonStandardCondition: true,
              nonStandardConditionStatus: "Pending"
            })
          }
        } else {
          const group = this.standardConditionDocs.find(g => g.value === statusName);
          if (group) {
            tags.push({
              type: group.groupName,
              value: group.value,
              description: group.description,
              required: group.required,
              standardCondition: true,
              standardConditionStatus: "Pending"
            })
          }
        }
      }
      this.updateApplicationRequiredDocumentsFn(
        this.application.ApplicationId,
        tags
      ).subscribe((a: Application) => {
        this.application = a;
        this.reload();
      });
    } else {
      const subs: Observable<any>[] = docs.map(doc => this.undoApplicationDocumentFn(doc.name, "", statusName, this.user?.UserId));
      if (type && tagObj) {
        if (type === 'nonStandardCondition') {
          tags[index] = {...tagObj, nonStandardCondition: true, nonStandardConditionStatus: "In Progress"}
        } else {
          tags[index] = {...tagObj, standardCondition: true, standardConditionStatus: "In Progress"}
        }
        subs.push(this.updateApplicationRequiredDocumentsFn(
          this.application.ApplicationId,
          tags
        ))
      }
      this.subscriptions.push(
        combineLatest(subs).pipe(
          this.toastService.retryableMessage({
            successMessage: 'Undo documents success',
            errorMessage: 'Failed to undo the documents',
            retryFn: () => {
              console.log('**** retry ', this);
              this.onUndoDocument(docs, statusName);
            }
          }),
          tap(r => {
            this.reload();
          })
        ).subscribe()
      );
    }
  }

  // onNonStandardConditionDocUpload(){
  //   this.subscriptions.push(
  //     this.dialogService.openConfirmationDialog({
  //       message: 'Please confirm',
  //       subMessage: 'Have you uploaded all documents for non-standard conditions<br/> and wish to submit the application to the credit team for review?'
  //     }).afterClosed().pipe(
  //       tap(async (r: ConfirmationDialogResult | undefined) => {
  //         if (r && r.readyForSubmission) {
  //           this.onSubmitForReview();
  //         }
  //     })
  //     ).subscribe()
  //   );
  // }
  onDeleteDocument(tagName: string, groupName: string) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to delete ' + (groupName ?? 'this tag') + '?'
      }).afterClosed().pipe(
        tap(async (r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            //const subs = docs.map(doc => this.deleteApplicationDocumentFn(doc.name, "", tagName));
            const currentTags = this.applicationDocumentTypes?.length ? this.applicationDocumentTypes :
              [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];
            const tags = currentTags.filter(t => t.value !== tagName);
            this.updateApplicationRequiredDocumentsFn(
              this.application.ApplicationId,
              tags ?? []
            ).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Documents Deleted',
                errorMessage: 'Failed to delete the documents',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onDeleteDocument(tagName, groupName);
                }
              })
            ).subscribe((a) => {
              this.application = a;
              this.reload();
            })
          }
        })
      ).subscribe()
    );
  }

  async onDownloadDocument(doc: AzureStorageDocument) {
    // const a = document.createElement('a') as any;
    // a.href = this.downloadApplicationDocumentUrlFn(doc.name, "", 0);
    // document.body.appendChild(a);
    // a.click();
    this.downloadApplicationDocumentUrlFn(doc.name, "", 0).pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename((doc.metadata as Metadata)?.['name'] ?? doc.name, blob)
      })
    ).subscribe()
  }

  getColumnTitles(column: string, table: 'major' | 'standard condition' | 'non-standard condition' = 'major'): string {
    switch (column) {
      case 'groupName':
        return table === 'major' ? 'Other Documents' : (
          table === 'standard condition' ? 'Standard Condition' : 'Non-standard Condition'
        );
      case 'status': return 'Status';
      case 'documents': return 'Documents';
      case 'checkBox': return '';
      case 'action': return '';
      case 'icon':
        return '';
      default: return column;
    }
  }

  onRequestAdditionalDoc() {
    const requiredTags: DocumentTag[] = this.applicationDocumentTypes?.length
      ? this.applicationDocumentTypes.filter(t => t.required)
      : applicationDefaultDocuments(this.application).filter(t => t.required);
    const dbTags: DocumentTag[] = this.applicationDocumentTypes?.length
      ? [...this.applicationDocumentTypes]
      : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];
    const allTags: DocumentTag[] = this.applicationDocumentTypes?.length
      ? [...this.applicationDocumentTypes]
      : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];
    for (const tag of applicationDefaultDocuments(this.application, true)) {
      if (!allTags.find(t => t.value.toLowerCase() === tag.value)) {
        allTags.push(tag);
      }
    }

    this.dialogService.openSelectableTagsDialog({
      previouslySelectedTags: requiredTags,
      allTags: allTags,
      maxTags: 100,
      enableCustomTag: true,
      enableNonStandardCondition: true,
    }).afterClosed().pipe(
      tap((r: SelectableTagsDialogResult | undefined) => {
        if (r && r.valid) {
          const tags = r.selectedTags.map((tag) => {tag.required = true; return tag;}) ?? [];
          dbTags.forEach((tag) => {
            if (!tags.find((t) => t.value == tag.value)) {
              tag.required = false;
              tags.push(tag);
            }
          });
          this.updateApplicationRequiredDocumentsFn(
            this.application.ApplicationId,
            tags
          ).subscribe((a) => {
            this.application = a;
            this.reload();
          });
        }
      })
    ).subscribe();
  }

  onSendNotification() {
    const applicant = applicantIndividual(this.application);
    const borrowerName = this.application.ApplicationType === "Consumer" ?
      (applicant?.GivenName ?? "") + " " + (applicant?.SurName ?? "") :
      this.application.entityName;
    this.dialogService.openDocumentNotificationDialog({
      docs: this.docs,
      application: this.application,
      getUserFn: this.getUserFn,
    }).afterClosed().pipe(
      tap((r: DocumentNotificationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          this.sendApplicationDocumentNotificationFn(
            this.docs,
            r.additionalInformation,
            this.application.AppInfo.BrokerAppID,
            this.application.ApplicationId,
            this.application.AppInfo.BrokerEntityName ?? '',
            r.emailList,
            borrowerName,
            this.user?.UserId ?? 0,
          ).pipe(
            this.toastService.retryableMessage({
              successMessage: 'Notification Sent',
              errorMessage: 'Failed to send the notification',
              retryFn: ()=> {
                console.log('**** retry ', this);
                this.sendApplicationDocumentNotificationFn(
                  this.docs,
                  r.additionalInformation,
                  this.application.AppInfo.BrokerAppID,
                  this.application.ApplicationId,
                  this.application.AppInfo.BrokerEntityName ?? '',
                  r.emailList,
                  borrowerName,
                  this.user?.UserId ?? 0,
                )
              }
            }),
          ).subscribe()

          if (r.removeFromList) {
            this.onComplete();
          }
        }
      })
    ).subscribe();
  }

  onSubmitForReview(submitted:boolean,notification:number) {

    if(submitted){
      let successMessage = "Submitted"
        if(notification){
          successMessage=`Submitted, notification email to ${notification} recipients`
        }
      this.subscriptions.push(
        this.createDocumentWorklistFn({
          applicationId: this.application.ApplicationId,
          createdTime: new Date(),
          userId: null,
          status: 'pending',
          entityName: this.application.entityName,
          brokerAppId: this.application.BrokerAppId
        }).pipe(
          this.toastService.retryableMessage({
            successMessage: successMessage,
            errorMessage: 'Failed to submit for review',
            retryFn: ()=> {
              console.log('**** retry ', this);
              this.createDocumentWorklistFn({
                applicationId: this.application.ApplicationId,
                createdTime: new Date(),
                userId: null,
                status: 'pending',
                entityName: this.application.entityName,
                brokerAppId: this.application.BrokerAppId
              })
            }
          })
        ).subscribe()
      )
    }

    if(!submitted && notification){
      this.toastService.simpleSuccess(`Notification email to ${notification} recipients`)
    }
    
    
  }

  onComplete() {
    this.subscriptions.push(
      this.completeDocumentWorklistFn(this.application.ApplicationId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Removed from the worklist',
          errorMessage: 'Failed to complete the worklist',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.completeDocumentWorklistFn(this.application.ApplicationId)
          }
        }),
      ).subscribe()
    )
  }

  onDragOver(event: DragEvent, element: GroupedDocument) {
    event.preventDefault();
    this.dragElement = element;
  }

  onDrop(event: DragEvent, element: GroupedDocument, isNonStandardConditionDocument: boolean = false) {
    event.preventDefault();
    this.dragElement = null;
    const files = event.dataTransfer?.files;
    const fileArray = [];
    if (files && files.length > 0) {
      for (const f of Array.from(files)) {
        console.log(f);
        (f as UploadAzureFile).tags = [element.value];
        (f as UploadAzureFile).metadata = this.metadata;
        fileArray.push(f);
      }

      this.events.emit({
        files: fileArray, removefileNames: [], isNonStandardConditionDocument
      });

      // need to double-check the tags
      const currentTag = getAllDefaultTags().find(t => t.value === element.value);
      const selectedTags = currentTag ? [currentTag] : [] ;
      const existingTags = this.applicationDocumentTypes?.length ?
        this.applicationDocumentTypes : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()];
      let tagChanges = false;
      for (const tag of selectedTags) {
        if (!existingTags.map(t => t.type).includes(tag.type)) {
          existingTags.push(tag);
          tagChanges = true;
        }
      }

      if (tagChanges) {
        this.updateApplicationRequiredDocumentsFn(
          this.application.ApplicationId,
          existingTags
        ).subscribe();
      }
    }
  }

  onSendApprovalNotice() {
    this.subscriptions.push(
      this.dialogService.openApprovalNoticeDialog({
        application: this.application,
        generateApprovalNoticeFn: this.generateApprovalNoticeFn,
        sendApprovalNoticeEmailFn: this.sendApprovalNoticeEmailFn,
        deleteApprovalNoticeFileFn: this.deleteApprovalNoticeFileFn,
        getApplicationOwnerFn: this.getApplicationOwnerFn,
        getUserFn: this.getUserFn,
        apiUrl: this.apiUrl,
        standardCondition: this.standardConditionDocs,
        nonStandardCondition: this.nonStandardConditionDocs,
        businessLoanData: this.businessLoanData,
      }).afterClosed().subscribe()
    )
  }

  get moreActionTooltips() {
    let text = "Find here additional action as: \n";
    if (!isInternalUser(this.user)){
      text += " - Download All as ZIP";
    } else {
      text += " - Download All as ZIP\n - Request Additional Docs\n - Send Notification\n - Complete\n - Approval Notice";
    }
    return text;
  }
}
