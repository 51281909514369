import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Application, ApplicationCreditOfficer, ApplicationOwner, CONTRACT_EMAIL_DOMAIN, CREDIT_EMAIL_DOMAIN, CustomerAngularEditorComponentValue, DocumentReviewDialogData, DocumentReviewDialogResult, GetApplicationCreditOfficerFn, GetApplicationOwnerFn, GetUserFn, GroupedDocument, isExternalUser, isInternalUser, SALES_EMAIL_DOMAIN, User } from '@portal-workspace/grow-shared-library';
import { ApplicationService } from 'apps/portal2/src/app/service/application.service';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';
import _ from 'lodash';
import { Subscription, tap } from 'rxjs';
import { getUser, setupUntilDestroy, validateEmail } from '../component-utils';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { ApplicationDialogService } from './application-dialog.service';
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './document-review.dialog.html',
  styleUrls: ['./document-review.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    NgClass,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
    CustomAngularEditorComponent,
    MatExpansionModule
]
})
export class DocumentReviewDialog implements OnInit {

  errorMessage: string = '';
  subscriptions: Subscription[] = [];
  displayedColumns = ['name', 'action'];
  loggedInUser: User | null = getUser();
  isExternalUser : boolean = isExternalUser(this.loggedInUser);
  getUserFn!: GetUserFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn
  isInternalUser = isInternalUser
  getApplicationCreditOfficerFn!: GetApplicationCreditOfficerFn

  data: DocumentReviewDialogData = inject(MAT_DIALOG_DATA);
  alltagStatus: string[] = [];
  filtertagStatus: string[] = [];
  dialogRef = inject(MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>);
  selectedGroupName = new SelectionModel<GroupedDocument>(true, []);
  salesEmail: string = "";
  creditEmail: string = "";
  submitterEmailList: string[] = [];
  submitterEmailListWithKeyName: { control: FormControl | null ; email: string; }[] = [];
  otherEmailList: string[] = [];
  application!: Application;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  submitterEmailError = false;
  otherEmailError = false;
  isSubmitEnabled: boolean = true
  isInternalOnly: boolean = false
  pendingCount: number = 0;
  isDocumentsConditionsExpanded = false
  inProgressCount: number = 0;
  editorHeight = 200;
  isShowRequestReviewSection: Boolean = false;
  title: string = 'Review Documents/Conditions';
  notesPlaceholder: string = `Provide background information to support your request to review these conditions.\n\nOur team will respond to you as soon as possible.`
  allTags: GroupedDocument[] = [];
  filterTag: GroupedDocument[] = [];
  removedTag: GroupedDocument[] = [];
  selectableTags: GroupedDocument[] = [];
  formControlApplicationNotes!: FormControl<CustomerAngularEditorComponentValue>;
  formControlInternalOnly: FormControl<boolean | null>;
  formControlCredit: FormControl<boolean | null>;
  formControlSales:FormControl<boolean | null>;
  formControlSettlements:FormControl<boolean | null>;
  formControlSubmitter: FormControl<boolean | null>;
  formControlOther: FormControl<boolean | null>;
  formControlSubmitterEmail!: FormControl<string | null>;
  formControlOtherEmail!: FormControl<string | null>;
  formControlAssignWorklist:FormControl<boolean | null>;

  constructor(
    private dialogService: ApplicationDialogService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DocumentReviewDialogData,
    private matDialogRef: MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private applicationService:ApplicationService,
    private toastService: PortalHotToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.isShowRequestReviewSection = dialogData.isShowRequestReviewSection
    this.formControlApplicationNotes = this.formBuilder.control('')
    this.formControlInternalOnly = this.formBuilder.control(false)
    this.formControlCredit = this.formBuilder.control(false)
    this.formControlSales = this.formBuilder.control(false)
    this.formControlSettlements = this.formBuilder.control(false)
    this.formControlSubmitter = this.formBuilder.control(false)
    this.formControlOther = this.formBuilder.control(true)
    this.formControlSubmitterEmail = this.formBuilder.control(null);
    this.formControlOtherEmail = this.formBuilder.control(null);
    this.formControlAssignWorklist = this.formBuilder.control(false)
    this.allTags = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    this.title = dialogData.title

    if(!this.isShowRequestReviewSection){
      this.notesPlaceholder = "";
      this.editorHeight = 100
    }
    
    if(this.isExternalUser && this.dialogData.groupNames.filter(arg=>arg.status === "In Progress").length){
      this.isDocumentsConditionsExpanded=true
    }
    
    if(this.isShowRequestReviewSection){
      if(isInternalUser(this.loggedInUser)){
        this.formControlSales.setValue(true)
        this.formControlSubmitter.setValue(true)
      }
    } 
  }

  ngOnInit() {
    setupUntilDestroy(this)
    const alltagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status')))
    .filter(status => status !== 'Accepted' && status !== '');
      this.alltagStatus = alltagStatus.sort((a, b) => {
      const order = ['Pending', 'In Progress']; // Define the preferred order
      const indexA = order.indexOf(a);
      const indexB = order.indexOf(b);

      if (indexA !== -1 || indexB !== -1) {
        return (indexA === -1 ? 2 : indexA) - (indexB === -1 ? 2 : indexB);
      }
      return 0;
    });

    this.data.groupNames.forEach(element => {
      this.selectedGroupName.select(element)
    });

    this.getUserFn = this.authService.getUserFn;
    this.getApplicationOwnerFn = this.applicationService.getApplicationOwnerFn;
    this.getApplicationCreditOfficerFn = this.applicationService.getApplicationCreditOfficerFn
    this.application = this.dialogData.application;
     
    if(this.isShowRequestReviewSection && isInternalUser(this.loggedInUser)){
      this.addEmail(this.application.AppInfo.UserEmail, this.formControlSubmitter);
      this.addSaleEmail()
    }
     
    this.subscriptions.push(this.formControlCredit.valueChanges.pipe(
      tap(arg => {
        if(arg){
          if(!this.creditEmail && _.has(this.application, "AppInfoSalesforceID")  && this.application.AppInfoSalesforceID){
            this.getApplicationCreditOfficerFn(this.application.AppInfoSalesforceID)
            .pipe(this.toastService.spinnerObservable())
            .subscribe(
              (Application: ApplicationCreditOfficer | null) => {
                if (Application && Application.CreditOfficerEmail) {
                  const email = Application.CreditOfficerEmail;
                  this.creditEmail = email 
                } else {
                  this.creditEmail = CREDIT_EMAIL_DOMAIN;
                }
                this.addEmail(this.creditEmail, this.formControlCredit);
              },
            );
          } else if(!this.creditEmail){
            this.creditEmail = CREDIT_EMAIL_DOMAIN;
            this.addEmail(this.creditEmail, this.formControlCredit);
          } else { this.addEmail(this.creditEmail, this.formControlCredit); }
        } else {
          if(this.creditEmail){
            this.removeEmail(this.creditEmail);
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSettlements.valueChanges.pipe(
      tap(arg => {
        if(arg){
          this.addEmail(CONTRACT_EMAIL_DOMAIN, this.formControlSettlements);
        }else {
          this.removeEmail(CONTRACT_EMAIL_DOMAIN)
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSales.valueChanges.pipe(
      tap(arg => {
        if(arg){
          this.addSaleEmail()
        } else {
          if(this.salesEmail){
          this.removeEmail(this.salesEmail);
          }
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlInternalOnly.valueChanges.pipe(
      tap(value => {
        if(value){
          this.formControlSubmitter.setValue(false);
          this.isInternalOnly = true
          this.removeEmail(this.application.AppInfo.UserEmail)
        } else {
          this.isInternalOnly = false
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlApplicationNotes.valueChanges.pipe(
      tap(value => {
        if ((value && !_.isEmpty(value.replace(/<[^>]*>/g, '').trim())) || this.filtertagStatus.length > 0){
          this.isSubmitEnabled = false
        } else {
          this.isSubmitEnabled = true
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formControlSubmitter.valueChanges.pipe(
      tap(value => {
         if(value){
          this.formControlInternalOnly.setValue(false);
          this.addEmail(this.application.AppInfo.UserEmail, this.formControlSubmitter);
         } else {
          this.removeEmail(this.application.AppInfo.UserEmail)
         }
      })
    ).subscribe());

    this.subscriptions.push(
      this.formControlSubmitterEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.submitterEmailError = false;
          this.isSubmitEnabled = true
        }
      })
    );

    this.subscriptions.push(
      this.formControlOtherEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.otherEmailError = false;
        }
      })
    )
  }

  addSaleEmail() {
    if (!this.salesEmail && this.application.sfOwnerEmail) {
      this.salesEmail = this.application.sfOwnerEmail;
      this.addEmail(this.salesEmail, this.formControlSales);
    }
    else if (!this.salesEmail && this.application.AppInfoSalesforceID) {
      this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
        .pipe(this.toastService.spinnerObservable())
        .subscribe(
          (Application: ApplicationOwner | null) => {
            if (Application && Application.Email) {
              const email = Application.Email;
              this.salesEmail = email
            } else {
              this.salesEmail = SALES_EMAIL_DOMAIN;
            }
            this.addEmail(this.salesEmail, this.formControlSales);
          },
        );
    } else if (!this.salesEmail) {
      this.salesEmail = SALES_EMAIL_DOMAIN;
      this.addEmail(this.salesEmail, this.formControlSales);
    } else {
      console.log("else this.salesEmail", this.salesEmail)
      this.addEmail(this.salesEmail, this.formControlSales);
    }
  }
  
  onPanelOpen() {
    if(this.filtertagStatus.length === 0 && this.filterTag.length === 0) {
      this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted' && status !== "");
      this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    }
    this.updateStatusCounts()
  }

  updateStatusCounts() {
    this.pendingCount = this.filterTag.filter(tag  => tag.status === 'Pending').length;
    this.inProgressCount = this.filterTag.filter(tag => tag.status === 'In Progress').length;
    const rejectedCount = this.filterTag.filter(tag => tag.status === 'Rejected').length;

    if(!this.pendingCount){
      _.pull(this.filtertagStatus, "Pending");
    }

    if(!this.inProgressCount){
      _.pull(this.filtertagStatus, "In Progress");
    }

    if(!rejectedCount){
      _.pull(this.filtertagStatus, "Rejected");
    }
    
    if((this.formControlApplicationNotes.value && !_.isEmpty(this.formControlApplicationNotes.value.replace(/<[^>]*>/g, '').trim())) || this.filtertagStatus.length > 0){
      this.isSubmitEnabled = false
    }else {
      this.isSubmitEnabled = true
    }
    this.cdr.detectChanges();
  }

  addEmail(email: string, control?: FormControl): void {
    if (email && !_.includes(this.submitterEmailList, email)) {
      this.submitterEmailList.push(email);
      if (control) {
        this.submitterEmailListWithKeyName.push({ control: control, email: email });
      }
    }
  }

  removeEmail(email: string): void{
    if (email) {
      _.pull(this.submitterEmailList, email);
      _.pull(this.otherEmailList, email);
      const result = _.find(this.submitterEmailListWithKeyName, { email: email });
      if(result){
        this.submitterEmailListWithKeyName = this.submitterEmailListWithKeyName.filter(item => item.email !== email);
        if (result.control) {
          result.control.setValue(false);
        }
      }
    }
  }

  remove(tag: GroupedDocument): void {
    _.pull(this.filterTag, tag);
    this.removedTag.push(tag)
    this.updateStatusCounts();
  }
 
  onSelectionChanged($event: MatCheckboxChange, element: GroupedDocument) {
    if($event.checked) {
      this.selectedGroupName.select(element)
    } else {
      this.selectedGroupName.deselect(element)
    }
  }
  onStatusSelectionChanged($event: MatCheckboxChange, element: string) {
    if($event.checked) {
      this.filtertagStatus.push(element)
    } else {
      _.pull(this.filtertagStatus, element);
    }
    this.filterTag = this.allTags.filter(item => this.filtertagStatus.includes(item.status) && !this.removedTag.includes(item));
    this.updateStatusCounts();
  }
  
  refresh(){
    this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted' && item.status !== '');
    this.removedTag = []
    this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted' && status !== '');
    this.updateStatusCounts();
  }

  onSave() {  
    const validNote = this.formControlApplicationNotes.value === '<p></p>' || null ? false : true;
    this.dialogRef.close({
      valid: true,
      groupNames:this.filterTag,
      documentNotes: validNote ? this.formControlApplicationNotes.value : null,
      isInternalUser: this.formControlInternalOnly.value,
      isCreditSelect: this.formControlCredit.value,
      isSalesSelect:this.formControlSales.value,
      isSettlementSelect:this.formControlSettlements.value,
      isSubmitterSelect: this.formControlSubmitter.value,
      submitterEmailList: this.submitterEmailList,
      isAssignWorklist: this.formControlAssignWorklist.value,
      otherEmailList:this.otherEmailList
    });
  }

  onCancel(event: Event) {
    this.dialogRef.close({type: 'cancel'});
  }
  
  addSubmitterEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.addEmail(value);
        if (!_.includes(this.otherEmailList, value)) {
          this.otherEmailList.push(value)
        }
      }
      // Clear the input value
      event.chipInput!.clear();
      this.submitterEmailError = false;
      if((this.formControlApplicationNotes.value && !_.isEmpty(this.formControlApplicationNotes.value.replace(/<[^>]*>/g, '').trim())) || this.filtertagStatus.length > 0){
        this.isSubmitEnabled = false
      }
    } else if (value) {
      this.isSubmitEnabled = true
      this.submitterEmailError = true;
    } else if (!value) {
      this.isSubmitEnabled = false
    }
  }
}